<template>
  <div>
    <el-collapse accordion>
      <draggable
        :list="materials"
        group="materials"
        @change="$emit('resetOrder')"
        :disabled="!canEdit"
      >
        <el-collapse-item
          :class="canEdit ? 'subject-item' : 'subject-item-show'"
          v-for="(item, itemIndex) in materials"
          :key="itemIndex"
        >
          <template slot="title">
            <div class="subject-item-title">
              <div>
                <div>
                  <span class="icon"><i class="fas fa-file-archive"></i></span>
                  {{ item.title }}
                  <small v-if="item.slug"> ({{ item.slug }}) </small>
                </div>
                <div class="subject-item-content-title">
                  {{ item.description }}
                </div>
              </div>
              <div>
                <span @click.stop="" v-if="canEdit">
                  <el-dropdown trigger="click">
                    <span class="more more-icon">
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <div
                          @click="
                            $emit('editFile', item, itemIndex, subjectIndex)
                          "
                        >
                          {{ $t("newCourses.Edit") }}
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div
                          @click="
                            $emit(
                              'removeData',
                              materials,
                              itemIndex,
                              'material'
                            )
                          "
                        >
                          {{ $t("newCourses.Delete") }}
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item divided :disabled="itemIndex === 0">
                        <div @click="$emit('upData', materials, itemIndex)">
                          {{ $t("newCourses.Move up") }}
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item
                        :disabled="itemIndex === materials.length - 1"
                      >
                        <div @click="$emit('downData', materials, itemIndex)">
                          {{ $t("newCourses.Move down") }}
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </div>
            </div>
          </template>
          <div class="subject-item-content subject-item-content-show">
            <!-- <b class="mb-0">Practices</b> -->
            <div
              v-if="
                item.session_class_assign_tests &&
                  item.session_class_assign_tests.length > 0
              "
            >
              <!-- <span class="text-info" style="line-height: 20px;">
                Drag and drop Practices to sort them.
              </span> -->
              <draggable
                :list="item.session_class_assign_tests"
                @change="resetPracticesOrder(item)"
              >
                <div
                  v-for="(test, index) in item.session_class_assign_tests"
                  :key="index"
                  class="file"
                >
                  <el-alert type="info" effect="dark" :closable="false">
                    <div class="alert file-detail">
                      <span>
                        <i class="fas fa-chart-bar"></i>
                        {{ test.exam.title }}
                      </span>
                      <span
                        class="text-danger file-detail-removes"
                        @click="removeTest(test.id)"
                      >
                        <i class="fas fa-times"></i>
                      </span>
                    </div>
                  </el-alert>
                </div>
              </draggable>
            </div>
            <!-- <el-empty v-else :image-size="75"></el-empty> -->
            <el-button
              type="primary"
              size="mini"
              @click="$emit('addPractice', item)"
            >
              <i class="fa fa-plus"></i>
              Add New Practice
            </el-button>
            <!-- <hr /> -->
            <!-- <b class="mt-0 mb-0">Materials</b> -->
            <div v-if="item.material_files && item.material_files.length > 0">
              <span class="text-info" style="line-height: 20px;">
                Drag and drop Materials to sort them.
              </span>
              <div>
                <draggable
                  :list="item.material_files"
                  @change="resetMaterialsOrder(item)"
                >
                  <div
                    v-for="(file, index) in item.material_files"
                    :key="index"
                    class="file"
                  >
                    <el-alert type="info" effect="dark" :closable="false">
                      <div class="alert file-detail">
                        <a
                          :href="file.link"
                          target="_blank"
                          v-if="file.type === 'file' || file.type === 'link'"
                        >
                          <i
                            class="fas fa-file-upload"
                            v-if="file.type === 'file'"
                          ></i>
                          <i
                            class="fas fa-link"
                            v-if="file.type === 'link'"
                          ></i>
                          {{ file.name }}
                        </a>
                        <span v-if="file.type === 'tests'">
                          <i class="fas fa-chart-bar"></i>
                          {{ file.name }}
                        </span>
                        <span v-if="file.type === 'videos'">
                          <i class="fas fa-video"></i>
                          {{ file.name }}
                        </span>
                        <span
                          class="text-danger file-detail-removes"
                          @click="removeFile(file.id)"
                        >
                          <i class="fas fa-times"></i>
                        </span>
                      </div>
                    </el-alert>
                  </div>
                </draggable>
              </div>
            </div>
            <!-- <el-empty v-else :image-size="75"></el-empty> -->
            <el-button
              type="primary"
              size="mini"
              @click="$emit('addFile', item)"
            >
              <i class="fa fa-plus"></i>
              Add New Material
            </el-button>
          </div>
          <!-- <div class="subject-item-content subject-item-content-show">
          </div> -->
          <div style="padding: 1rem 1.5rem" v-if="false">
            <a href="">
              {{ $t("newCourses.Detail") }}
            </a>
          </div>
        </el-collapse-item>
      </draggable>
    </el-collapse>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import draggable from "vuedraggable";
import Materials from "@/apis/materials";
export default {
  metaInfo() {},

  components: {
    draggable
  },

  mixins: [],

  props: ["canEdit", "materials", "subjectIndex"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    removeTest(id) {
      let vm = this;
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        await Materials.removeTest(id);
        vm.$emit("getSubjects");
      });
    },
    removeFile(id) {
      let vm = this;
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        await Materials.removeFile(id);
        vm.$emit("getSubjects");
      });
    },
    async resetPracticesOrder(item){
      await Materials.testOrder(item.id,{
        session_class_assign_tests: item.session_class_assign_tests
      });
      this.$emit("getSubjects");
    },
    async resetMaterialsOrder(item) {
      await Materials.filesOrder(item.id, {
        material_files: item.material_files
      });
      this.$emit("getSubjects");
    }
  }
};
</script>

<style scoped>
.more-icon {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5rem;
  font-size: 1rem;
}
.more-icon:hover {
  background-color: rgba(32, 33, 36, 0.039);
}

.subject-item,
.subject-item-show {
  border-bottom: 1px solid #ebeef5;
  /* pointer-events: none; */
}
::v-deep .el-collapse-item__header {
  height: 100px;
}

::v-deep .el-collapse-item__header,
::v-deep .el-collapse-item__wrap,
::v-deep .el-collapse {
  border-bottom: none;
  background-color: transparent;
}
::v-deep .el-collapse-item__arrow {
  display: none;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0;
}
.subject-item-title {
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  line-height: 2rem;
  cursor: move;
}
.subject-item-content {
  display: none;
  padding: 1rem 1.5rem 1rem 4rem;
  border-top: 0.0625rem solid #e0e0e0;
  /* border-bottom: 0.0625rem solid #e0e0e0; */
}

.file {
  margin: 0.5rem 0;
}
::v-deep .el-alert__content {
  width: 100%;
}

.file {
  border-radius: 0.5rem;
  margin: 0 0 1rem 0;
}
.file:hover {
  border-radius: 0.5rem;
  cursor: move;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}
.file-detail-removes {
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0;
}
.file-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
.subject-item-content-title {
  font-size: 0.8125rem;
}
.subject-item-content-show {
  display: block;
}
.subject-item-title .icon {
  font-size: 1rem;
  margin-right: 0.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #42a16a;
  color: white;
  text-align: center;
}
.subject-item-title .date {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.549);
}
.subject-item:hover {
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}
.subject-item .more {
  margin-left: 1rem;
  color: white;
}
.subject-item:hover .more {
  color: #606266;
}
</style>
