<template>
  <div>
    <!-- 新增、编辑分類 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="
        subjectInfo.isEdit ? $t('newCourses.Rename') : $t('newCourses.New Unit')
      "
      :visible.sync="editSubjectDialog"
      width="30%"
      center
    >
      <el-input
        type="text"
        :placeholder="$t('newCourses.Category')"
        v-model="subjectInfo.name"
        :maxlength="20"
        show-word-limit
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editSubjectDialog = false">
          {{ $t("newCourses.Cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="saveSubject"
          :disabled="subjectInfo.name === ''"
        >
          {{ $t("newCourses.Confirm") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 新增、编辑Materials的基本信息 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="
        fileForm.isEdit
          ? $t('newCourses.Edit Folder')
          : $t('newCourses.New Folder')
      "
      :visible.sync="editHomeWorkDialog"
      width="70%"
      center
    >
      <div class="container">
        <el-form ref="form" :model="fileForm" label-width="90px">
          <el-form-item
            :label="$t('newCourses.Category')"
            required
            v-if="!fileForm.isEdit"
          >
            <el-select v-model="selectedSubject" style="width:100%">
              <el-option
                v-for="(subject, index) in subjects"
                :key="index"
                :label="subject.name"
                :value="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('newCourses.Folder Name')" required>
            <el-input
              type="text"
              :placeholder="$t('newCourses.Folder Name')"
              v-model="fileForm.title"
              :maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('newCourses.Description')">
            <el-input
              type="textarea"
              :placeholder="$t('newCourses.Description')"
              v-model="fileForm.description"
              :rows="6"
              :maxlength="500"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="Link">
            <templates slot="label">
              Link
              <el-tooltip
                content="透過將該網址和QRCode網址綁定，使用者可以透過掃描QRCode進行訪問。"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </templates>
            <el-input
              type="text"
              placeholder="EX: e1cw1 (English Point 1 ClassWork) "
              v-model="fileForm.slug"
              :maxlength="10"
              show-word-limit
            >
              <template slot="prepend">
                https://ivy-way.com/get-folder?unit_slug=
              </template>
            </el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editHomeWorkDialog = false">
          {{ $t("newCourses.Cancel") }}
        </el-button>
        <el-button type="primary" @click="saveFile">
          {{ $t("newCourses.Confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 新增File -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('newCourses.New Materials')"
      :visible.sync="addFileShow"
      width="70%"
      center
    >
      <div class="container">
        <el-form ref="form" :model="fileForm" label-width="90px">
          <el-form-item :label="$t('newCourses.Materials')">
            <div>
              <div class="d-flex" style=" justify-content:space-between;">
                <el-radio-group v-model="fileType" class="mb-3">
                  <el-radio-button label="file">
                    <i class="fas fa-file"></i>
                    {{ $t("newCourses.File") }}
                  </el-radio-button>
                  <el-radio-button label="link">
                    <i class="fas fa-link"></i>
                    {{ $t("newCourses.Link") }}
                  </el-radio-button>
                  <el-radio-button label="videos">
                    <i class="fab fa-youtube"></i>
                    Youtube Video
                  </el-radio-button>
                  <el-radio-button label="exam">
                    <i class="fas fa-chart-bar"></i>
                    Practice
                  </el-radio-button>
                </el-radio-group>
              </div>
              <template v-if="fileType === 'exam'">
                <div class="add-link">
                  <el-select
                    filterable
                    style="width:100%;margin-right:10px"
                    v-model="testNow"
                    placeholder="選擇考卷"
                  >
                    <el-option
                      v-for="(item, index) in sessionsTestsOnlineSATPractices"
                      :key="index"
                      :label="item.title"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                  <el-button
                    type="success"
                    @click="addTest"
                    :disabled="testNow == null"
                  >
                    {{ $t("newCourses.Add") }}
                  </el-button>
                </div>
              </template>
              <template v-else>
                <el-upload
                  v-loading="loading"
                  element-loading-text="Uploading..."
                  element-loading-spinner="el-icon-loading"
                  v-show="fileType === 'file'"
                  class="upload-demo"
                  drag
                  name="file"
                  :action="postUrl"
                  :headers="token"
                  :data="{ session_class_id: classId }"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :on-success="handleUploadSuccess"
                  :on-error="errorUpload"
                >
                  <div slot="tip" class="el-upload__tip">
                    <b>{{
                      $t("newCourses.File cannot be bigger than 20 mb")
                    }}</b>
                  </div>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    {{ $t("newCourses.Drag file here or upload") }}
                  </div>
                </el-upload>
                <div v-show="fileType === 'link'" class="add-link">
                  <el-input
                    type="text"
                    placeholder="https://Example.com"
                    v-model="fileLink"
                  ></el-input>
                  <el-button
                    type="success"
                    @click="addLink"
                    :disabled="!(fileLink !== '')"
                  >
                    {{ $t("newCourses.Add") }}
                  </el-button>
                </div>
                <div v-show="fileType === 'videos'">
                  <div class="add-link mb-3">
                    <el-input
                      type="text"
                      placeholder="https://youtu.be/dQw4w9WgXcQ"
                      v-model="fileLink"
                    ></el-input>
                    <el-button
                      type="success"
                      @click="addLink"
                      :disabled="!(fileLink !== '')"
                    >
                      {{ $t("newCourses.Add") }}
                    </el-button>
                  </div>
                  <img
                    style="width: 300px;"
                    src="https://ivy-way.s3.ap-northeast-1.amazonaws.com/images/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240709193030.png"
                    alt=""
                  />
                </div>
              </template>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <Heading heading="h4" :content="myTitle" class="mb-4"></Heading>
    <hr />
    <!-- 展示作业 -->
    <!-- <el-form>
      <el-form-item label="">
        <div class="d-flex">
          <el-select
            filterable
            style="width:100%"
            v-model="template"
            placeholder="您可以選擇其他班級的Materials，並導入自己的班級。"
            @change="setTests"
          >
          </el-select>
          <div class="ml-4">
            <el-button type="primary" size="">
              <i class="fas fa-file-import"></i>
              Import
            </el-button>
          </div>
        </div>
      </el-form-item>
    </el-form> -->
    <el-card v-if="subjects">
      <div class="video-box">
        <div class="edu-play-left">
          <div class="section-card-list">
            <el-collapse
              accordion
              v-model="subjectNow"
              v-if="subjects.length > 0"
            >
              <draggable :list="subjects" @change="resetOrder">
                <!-- Points -->
                <el-collapse-item
                  v-for="(subject, subjectIndex) in subjects"
                  :key="subjectIndex"
                  :name="subjectIndex"
                  v-model="activeSubject"
                >
                  <template slot="title">
                    <div
                      style="width: 100%;  display: flex; align-items: center;justify-content: space-between;"
                    >
                      <h5
                        :class="
                          subjectIndexNow === subjectIndex
                            ? 'text-success mt-4 mb-4'
                            : ' mt-4 mb-4'
                        "
                      >
                        <span v-show="subjectNow !== subjectIndex">
                          <i class="fas fa-folder"></i>
                        </span>
                        <span v-show="subjectNow === subjectIndex">
                          <i class="fas fa-folder-open"></i>
                        </span>
                        {{ `${subject.name}` }}
                      </h5>
                      <div class="d-flex mr-2">
                        <el-dropdown trigger="click">
                          <span class="more-icon" @click.stop="">
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                              <div @click="createFile(subjectIndex)">
                                {{ $t("newCourses.New Folder") }}
                              </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <div @click="editSubject(subjectIndex)">
                                {{ $t("newCourses.Rename") }}
                              </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <div
                                @click.stop="
                                  removeSubjectAlert(
                                    subjects,
                                    subjectIndex,
                                    'subject'
                                  )
                                "
                              >
                                {{ $t("newCourses.Delete") }}
                              </div>
                            </el-dropdown-item>
                            <el-dropdown-item
                              divided
                              :disabled="subjectIndex === 0"
                            >
                              <div @click="upData(subjects, subjectIndex)">
                                {{ $t("newCourses.Move up") }}
                              </div>
                            </el-dropdown-item>
                            <el-dropdown-item
                              :disabled="subjectIndex === subjects.length - 1"
                            >
                              <div @click="downData(subjects, subjectIndex)">
                                {{ $t("newCourses.Move down") }}
                              </div>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>
                  </template>
                  <div class="subject-children">
                    <el-collapse
                      v-model="materialsIndexNow"
                      v-if="subject.materials.length > 0"
                      accordion
                    >
                      <draggable
                        :list="subject.materials"
                        group="materials"
                        @change="resetOrder"
                      >
                        <!-- Types -->
                        <el-collapse-item
                          v-for="(lesson, materialsIndex) in subject.materials"
                          :key="materialsIndex"
                          :name="materialsIndex"
                        >
                          <template slot="title">
                            <div
                              class="pl-4"
                              style="width: 100%;  display: flex; align-items: center;justify-content: space-between;"
                            >
                              <div class="pb-3">
                                <h6 class="mt-3 mb-1">
                                  <span
                                    v-show="
                                      materialsIndexNow !== materialsIndex
                                    "
                                  >
                                    <i class="fas fa-folder"></i>
                                  </span>
                                  <span
                                    v-show="
                                      materialsIndexNow === materialsIndex
                                    "
                                  >
                                    <i class="fas fa-folder-open"></i>
                                  </span>
                                  {{ `${lesson.title}` }}
                                  <div
                                    class="mt-1"
                                    v-if="lesson.description"
                                    style="line-height: 1.2; opacity: .5; font-size: 90%;word-break: break-all!important;"
                                  >
                                    {{ lesson.description }}
                                  </div>

                                  <el-popover placement="top" trigger="hover">
                                    <div>
                                      <el-button
                                        type="primary"
                                        size="mini"
                                        style="width: 100%;"
                                        @click="
                                          copyLink(
                                            `https://ivy-way.com/get-folder?unit_slug=${lesson.slug}`
                                          )
                                        "
                                      >
                                        <i class="fas fa-copy"></i>
                                        {{ $t("newCourses.Copy Link") }}
                                      </el-button>
                                    </div>
                                    <el-button
                                      class="text-info"
                                      style="font-style: italic;display: block;"
                                      slot="reference"
                                      type="text"
                                      size="mini"
                                      v-if="lesson.slug"
                                    >
                                      {{
                                        `https://ivy-way.com/get-folder?unit_slug=${lesson.slug}`
                                      }}
                                    </el-button>
                                  </el-popover>
                                  <small> </small>
                                </h6>
                              </div>
                              <div class="d-flex mr-2">
                                <el-dropdown trigger="click">
                                  <span class="more more-icon" @click.stop="">
                                    <i
                                      class="fa-solid fa-ellipsis-vertical"
                                    ></i>
                                  </span>
                                  <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                      <div @click="addFile(lesson)">
                                        {{ $t("newCourses.New Materials") }}
                                      </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                      <div
                                        @click="
                                          editFile(
                                            lesson,
                                            materialsIndex,
                                            subjectIndex
                                          )
                                        "
                                      >
                                        {{ $t("newCourses.Rename") }}
                                      </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                      <div
                                        @click="
                                          removeData(
                                            subject.materials,
                                            materialsIndex,
                                            'material'
                                          )
                                        "
                                      >
                                        {{ $t("newCourses.Delete") }}
                                      </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                      divided
                                      :disabled="materialsIndex === 0"
                                    >
                                      <div
                                        @click="
                                          $emit(
                                            'upData',
                                            subject.materials,
                                            materialsIndex
                                          )
                                        "
                                      >
                                        {{ $t("newCourses.Move up") }}
                                      </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                      :disabled="
                                        materialsIndex ===
                                          subject.materials.length - 1
                                      "
                                    >
                                      <div
                                        @click="
                                          $emit(
                                            'downData',
                                            subject.materials,
                                            materialsIndex
                                          )
                                        "
                                      >
                                        {{ $t("newCourses.Move down") }}
                                      </div>
                                    </el-dropdown-item>
                                  </el-dropdown-menu>
                                </el-dropdown>
                              </div>
                            </div>
                          </template>
                          <div style="padding-left: 1.5rem;">
                            <template v-if="lesson.material_files.length > 0">
                              <draggable
                                :list="lesson.material_files"
                                @change="resetMaterialsOrder(lesson)"
                              >
                                <div
                                  v-for="(file, index) in lesson.material_files"
                                  :key="index"
                                  @click="
                                    selectMaterial(
                                      subjectIndex,
                                      materialsIndex,
                                      index
                                    )
                                  "
                                >
                                  <div
                                    :class="
                                      subjectIndexNow === subjectIndex &&
                                      materialsIndexNow == materialsIndex &&
                                      fileIndexNow == index
                                        ? 'section-season p-2 active'
                                        : 'section-season p-2'
                                    "
                                    style="min-height: 4rem;align-items:center; display: flex; align-items: center;justify-content: space-between;"
                                  >
                                    <div style="flex:1;" class="pl-4">
                                      <div
                                        class="season-title"
                                        style="width: 100%;"
                                      >
                                        <b v-show="file.type === 'file'">
                                          <i class="fas fa-file"></i>
                                          File
                                        </b>
                                        <b v-show="file.type === 'link'">
                                          <i class="fas fa-link"></i>
                                          Link
                                        </b>
                                        <b v-show="file.type === 'videos'">
                                          <i class="fas fa-video"></i>
                                          Video
                                        </b>
                                        <b v-show="file.type === 'exam'">
                                          <i class="fas fa-chart-bar"></i>
                                          Practice
                                        </b>
                                      </div>
                                      <div class="season-info">
                                        <b>{{ file.name }}</b>
                                      </div>
                                    </div>
                                    <div>
                                      <el-dropdown trigger="click">
                                        <span
                                          class="more more-icon"
                                          @click.stop=""
                                        >
                                          <i
                                            class="fa-solid fa-ellipsis-vertical"
                                          ></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                          <el-dropdown-item
                                            v-if="file.type === 'exam'"
                                          >
                                            <div
                                              @click="
                                                removeTest(
                                                  file.id,
                                                  file.session_class_assign_test_id
                                                )
                                              "
                                            >
                                              {{ $t("newCourses.Delete") }}
                                            </div>
                                          </el-dropdown-item>
                                          <el-dropdown-item v-else>
                                            <div @click="removeFile(file.id)">
                                              {{ $t("newCourses.Delete") }}
                                            </div>
                                          </el-dropdown-item>
                                          <el-dropdown-item
                                            divided
                                            :disabled="index === 0"
                                          >
                                            <div
                                              @click="
                                                upData(
                                                  lesson.material_files,
                                                  index
                                                )
                                              "
                                            >
                                              {{ $t("newCourses.Move up") }}
                                            </div>
                                          </el-dropdown-item>
                                          <el-dropdown-item
                                            :disabled="
                                              index ===
                                                lesson.material_files.length - 1
                                            "
                                          >
                                            <div
                                              @click="
                                                downData(
                                                  lesson.material_files,
                                                  index
                                                )
                                              "
                                            >
                                              {{ $t("newCourses.Move down") }}
                                            </div>
                                          </el-dropdown-item>
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </div>
                                  </div>
                                </div>
                              </draggable>
                            </template>
                            <el-empty
                              v-else
                              description="No Materials."
                              :image-size="50"
                            >
                            </el-empty>
                            <div class="text-center mb-3" style="padding-left: 1rem; padding-right: 1rem;">
                              <el-button
                                type="primary"
                                size="small"
                                style="width: 100%;"
                                @click="addFile(lesson)"
                              >
                                <i class="fa fa-plus"></i>
                                {{ $t("newCourses.New Materials") }}
                              </el-button>
                            </div>
                          </div>
                        </el-collapse-item>
                      </draggable>
                    </el-collapse>
                    <el-empty
                      v-else
                      description="No Folders."
                      :image-size="100"
                    >
                    </el-empty>
                    <div
                      class="text-center pt-3 pb-3"
                      style="background-color: #fafafa; padding-left: 2.5rem; padding-right: 1rem;"
                    >
                      <el-button
                        type="primary"
                        @click="createFile(subjectIndex)"
                        style="width: 100%;"
                        size="small"
                      >
                        <i class="fa fa-plus"></i>
                        {{ $t("newCourses.New Folder") }}
                      </el-button>
                    </div>
                  </div>
                </el-collapse-item>
              </draggable>
            </el-collapse>
            <div class="p-3">
              <el-button
                type="primary"
                size="default"
                style="width: 100%;"
                @click="createSubject"
              >
                <i class="fa fa-plus"></i>
                {{ $t("newCourses.New Unit") }}
              </el-button>
            </div>
          </div>
        </div>
        <div class="edu-play-right" v-if="file && file.type">
          <div class="ml-4 mt-4">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>
                <b>{{ subjects[subjectIndexNow].name }}</b>
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <b>
                  {{
                    subjects[subjectIndexNow].materials[materialsIndexNow].title
                  }}
                </b>
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span v-show="file.type === 'link'">
                  <i class="fas fa-link"></i>
                  {{ $t("newCourses.Link") }}
                </span>
                <span v-show="file.type === 'file'">
                  <i class="fas fa-file"></i>
                  File
                </span>
                <span v-show="file.type === 'videos'">
                  <i class="fas fa-video"></i>
                  Video
                </span>
                <span v-show="file.type === 'exam'">
                  <i class="fas fa-chart-bar"></i>
                  Practice
                </span>
              </el-breadcrumb-item>
              <!-- <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
            </el-breadcrumb>
          </div>
          <div v-show="file.type === 'link'">
            <div class="fileContainer">
              <h4>
                <i class="fas fa-link"></i>
                {{ $t("newCourses.Link") }}
              </h4>
              <hr />
              <el-form label-width="100px">
                <el-form-item label="Link">
                  <b class="text-success">
                    <a :href="file.name">
                      {{ file.name }}
                    </a>
                  </b>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div v-show="file.type === 'file'">
            <div class="fileContainer">
              <h4>
                <i class="fas fa-file"></i>
                File
              </h4>
              <hr />
              <el-form label-width="100px">
                <!-- <el-form-item label="Type">
                  <i class="fas fa-file"></i>
                  {{ $t("newCourses.File") }}
                </el-form-item> -->
                <el-form-item label="File">
                  <b class="text-success">
                    <a :href="file.name">
                      {{ file.name }}
                    </a>
                  </b>
                </el-form-item>
              </el-form>
              <!-- <a :href="file.link">
                <el-button
                  type="primary"
                  @click="startTest"
                  style="width: 100%;"
                >
                  <i class="fa fa-eye"></i>
                  Preview
                </el-button>
              </a> -->
            </div>
          </div>
          <div v-show="file.type === 'videos'">
            <div class="fileContainer">
              <h4>
                <i class="fas fa-video"></i>
                Video
              </h4>
              <hr />
              <div class="videoContainer">
                <template>
                  <template v-if="file.link">
                    <iframe
                      v-if="getVideo(file.link)"
                      :src="getVideo(file.link)"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div v-show="file.type === 'exam'">
            <div>
              <div
                class="fileContainer"
                v-if="
                  file.session_class_assign_test &&
                    file.session_class_assign_test.exam
                "
              >
                <h4>
                  <i class="fas fa-chart-bar"></i>
                  Practice
                </h4>
                <hr />
                <el-form label-width="100px">
                  <el-form-item label="Practice">
                    <b class="text-success">
                      {{ file.name }}
                    </b>
                  </el-form-item>
                  <el-form-item label="Questions">
                    <b class="text-success">
                      {{ file.session_class_assign_test.exam.question_count }}
                    </b>
                    Questions
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import draggable from "vuedraggable";
import moment from "moment";
import JwtService from "@/common/jwt.service";
import Materials from "@/views/courses/materials/apis/materials";
import SubjectSection from "@/views/courses/materials/SubjectSection";
import courseApi from "@/apis/course";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - Ivy-Way Academy`
    };
  },

  components: {
    draggable
  },

  mixins: [],

  props: ["classId"],
  data() {
    return {
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      activeSection: "all",
      subjects: [{"id":84,"name":"测试","materials":[{"id":160,"material_subject_id":84,"slug":null,"title":"测试","description":"123123","publish_at":"2024-11-27","material_files":[{"id":559,"material_id":160,"order":0,"type":"link","name":"https://www.baidu.com","link":"https://www.baidu.com","extension":null,"session_class_assign_test_id":null,"session_class_assign_test":null}],"session_class_assign_tests":[]}]},{"id":85,"name":"测试2","materials":[]}],
      subjectInfo: {
        name: "",
        isEdit: false,
        index: 0
      },
      loading: false,
      materialsWithoutSubject: [],
      editSubjectDialog: false,
      editHomeWorkDialog: false,
      selectedSubject: 0,
      fileType: "file",
      fileLink: "",
      testNow: null,
      materialNow: null,
      subjectNow: null,
      showAdd: false,
      addFileShow: false,
      showPracticesAdd: false,
      fileForm: {
        isEdit: false,
        index: 0,
        title: "",
        slug: null,
        description: "",
        publish_at: moment(new Date()).format("YYYY-MM-DD")
      },
      newFile: {
        type: "file",
        name: "type",
        link: "type",
        extension: ""
      },

      activeSubject: "all",
      sessionsTestsOnlineSATPractices: [],
      subjectIndexNow: null,
      materialsIndexNow: null,
      fileIndexNow: null,
      file: null
    };
  },
  computed: {
    myTitle() {
      return this.$t("newCourses.Edit Materials");
    },
    canEdit() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "materials/file";
    }
    // activeSubject() {
    //   let activeSubject = "all";
    //   if (this.$route.query.subject) {
    //     activeSubject = this.$route.query.subject;
    //   }
    //   return activeSubject;
    // }
  },
  watch: {},

  mounted() {
    // this.getSubjects();
    // this.getAssignTest();
  },

  methods: {
    getVideo(url) {
      return url.replace("youtu.be/", "www.youtube.com/embed/");
    },
    copyLink(link) {
      var oInput = document.createElement("input");
      oInput.value = link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    },
    selectMaterial(subjectIndex, materialsIndex, fileIndex) {
      this.subjectIndexNow = subjectIndex;
      this.materialsIndexNow = materialsIndex;
      this.fileIndexNow = fileIndex;
      this.file =
        this.subjects &&
        this.subjects.length > 0 &&
        this.subjects[this.subjectIndexNow].materials.length > 0
          ? this.subjects[this.subjectIndexNow].materials[
            this.materialsIndexNow
          ].material_files[this.fileIndexNow]
          : null;
    },
    async getAssignTest() {
      const res = await courseApi.getAllExams({
        type: "SATMOCKS"
      });
      this.sessionsTestsOnlineSATPractices = res.exams;
    },
    checkUrl(link) {
      var reg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;
      if (!reg.test(link)) {
        this.$message({
          message: this.$t("newCourses.Invalid link!"),
          type: "warning"
        });
        return false;
      } else {
        return true;
      }
    },
    showSubject(index) {
      // console.log(index);
      // this.$router.replace({
      //   name: "classDetail",
      //   params: { ...this.$route.params },
      //   query: { ...this.$route.query, section: "materials", subject: index }
      // });
      this.activeSubject = index;
    },
    async resetOrder() {
      await Materials.resetOrder({ material_subjects: this.subjects });
      this.getSubjects();
    },
    //添加文件
    async handleUploadSuccess(response) {
      this.loading = false;
      await Materials.addFile(this.materialNow, {
        type: this.fileType,
        ...response
      });
      this.addFileShow = false;
      this.getSubjects();
    },
    //添加link和视频
    async addLink() {
      if (this.checkUrl(this.fileLink)) {
        await Materials.addFile(this.materialNow, {
          type: this.fileType,
          name: this.fileLink,
          link: this.fileLink,
          extension: ""
        });
        this.fileLink = "";
        this.addFileShow = false;
        this.getSubjects();
      }
    },
    //添加考试
    async addTest() {
      const exam = this.sessionsTestsOnlineSATPractices[this.testNow];
      await Materials.addTest(this.materialNow, {
        session_class_id: this.classId,
        exam_id: exam.id,
        exam_type: "App\\Entities\\SatMocks\\Exam"
      });
      this.testNow = null;
      this.addFileShow = false;
      this.getSubjects();
    },
    beforeUpload(file) {
      this.loading = true;
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error(
          this.$t("newCourses.File cannot be bigger than 20 mb")
        );
        this.loading = false;
      }
      return isLt20M;
    },
    errorUpload() {
      this.loading = false;
    },
    async getSubjects() {
      const res = await Materials.getSubjects(this.classId);
      this.subjects = res.material_subjects ? res.material_subjects : [];
      this.materialsWithoutSubject = res.materials ? res.materials : [];
    },
    // arr上下移动
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    removeTest(fileId, testId) {
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        // await Materials.removeTest(testId);
        await Materials.removeFile(fileId);
        this.getSubjects();
      });
    },
    removeFile(id) {
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        await Materials.removeFile(id);
        this.getSubjects();
      });
    },
    upData(arr, index) {
      let newArr = [];
      if (arr.length > 1 && index !== 0) {
        newArr = this.swapItems(arr, index, index - 1);
      } else {
        newArr = arr;
      }
      arr = newArr;
      this.resetOrder();
    },
    downData(arr, index) {
      let newArr = [];
      if (arr.length > 1 && index !== arr.length - 1) {
        newArr = this.swapItems(arr, index, index + 1);
      } else {
        newArr = arr;
      }
      arr = newArr;
      this.resetOrder();
    },
    createSubject() {
      this.subjectInfo = {
        name: "",
        isEdit: false,
        index: 0
      };
      this.editSubjectDialog = true;
    },
    editSubject(index) {
      this.editSubjectDialog = true;
      this.subjectInfo = {
        name: this.subjects[index].name,
        isEdit: true,
        index: index
      };
    },
    removeSubjectAlert(subjects, index) {
      this.$confirm(
        this.$t(
          "newCourses.Do you want to delete this category AND all the materials in it?"
        ),
        {
          confirmButtonText: this.$t("newCourses.Delete BOTH"),
          cancelButtonText: this.$t("newCourses.Only delete the category"),
          type: "warning"
        }
      )
        .then(() => {
          this.removeSubjectData(subjects, index, "subject");
        })
        .catch(() => {
          this.removeData(subjects, index, "subject");
        });
    },
    removeSubjectData(arr, index, type) {
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        await Materials.removeSubjectAll(arr[index].id);
        this.getSubjects();
      });
    },
    async removeData(arr, index, type) {
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        if (type === "subject") {
          await Materials.removeSubject(arr[index].id);
        } else if (type === "material") {
          await Materials.removeMaterial(arr[index].id);
        } else if (type === "file") {
          arr.splice(index, 1);
        } else if (type === "practices") {
          arr.splice(index, 1);
        }
        this.getSubjects();
      });
    },
    // 重新排序文件和考试的顺序
    async resetMaterialsOrder(item) {
      await Materials.filesOrder(item.id, {
        material_files: item.material_files
      });
      this.getSubjects();
    },
    async saveSubject() {
      if (this.subjectInfo.isEdit) {
        await Materials.updateSubject(
          this.subjects[this.subjectInfo.index].id,
          { name: this.subjectInfo.name }
        );
      } else {
        await Materials.createSubject(this.classId, {
          name: this.subjectInfo.name
        });
      }
      this.getSubjects();
      this.editSubjectDialog = false;
    },
    createFile(subjectIndex) {
      this.fileForm = {
        isEdit: false,
        index: 0,
        title: "",
        slug: "",
        description: "",
        publish_at: moment(new Date()).format("YYYY-MM-DD")
      };
      this.selectedSubject = Number(this.activeSubject)
        ? Number(this.activeSubject)
        : 0;
      this.selectedSubject = subjectIndex;
      this.editHomeWorkDialog = true;
    },
    editFile(item, itemIndex, subjectIndex) {
      this.fileForm = {
        ...item,
        isEdit: true,
        index: itemIndex
      };
      this.selectedSubject = subjectIndex;
      this.editHomeWorkDialog = true;
    },
    async saveFile() {
      if (this.fileForm.isEdit) {
        await Materials.updateMaterial(
          this.subjects[this.selectedSubject].materials[this.fileForm.index].id,
          { ...this.fileForm }
        );
      } else {
        await Materials.createMaterial({
          material_subject_id: this.subjects[this.selectedSubject].id,
          ...this.fileForm
        });
      }
      this.getSubjects();
      this.editHomeWorkDialog = false;
    },
    addFile(item) {
      this.addFileShow = true;
      this.materialNow = item.id;
    }
  }
};
</script>

<style scoped>
.add-link {
  display: flex;
}
.el-menu {
  border: none;
}
.subject-content {
  border-left: solid 1px #e6e6e6;
}
::v-deep .add-link .el-input {
  margin-right: 20px;
}
::v-deep .el-dropdown-menu__item {
  padding: 0;
}
::v-deep .el-dropdown-menu__item--divided:before {
  margin: 0;
}
::v-deep .el-dropdown-menu__item > div {
  padding: 0 20px;
  text-align: center;
}
.file-detail-removes {
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0;
}
::v-deep .alert {
  padding: 0;
}
.box {
  /* padding-top: 20px; */
  padding-bottom: 1rem;
}
.course-menu {
  margin-bottom: 2rem;
}

.subject-section:hover {
  cursor: move;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}
.subject-section {
  padding: 0 1rem 1rem 1rem;
  margin: 1rem 0;
  /* pointer-events: none; */
}
.subject-section-active {
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}
.subject-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  border-bottom: 1px solid #42a16a;
  height: 4rem;
}
.more-icon {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5rem;
  font-size: 1rem;
}
.more-icon:hover {
  background-color: rgba(32, 33, 36, 0.039);
}
.subject-title h4 {
  margin: 0;
}
.file {
  border-radius: 0.5rem;
  margin: 0 0 1rem 0;
}
.file:hover {
  border-radius: 0.5rem;
  cursor: move;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}
::v-deep .el-alert__content {
  width: 100%;
}
.file-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
::v-deep .container .el-card__body {
  padding-top: 0;
  padding-right: 24px;
}
::v-deep .el-dialog__body {
  max-height: 40rem;
  overflow: auto;
}
</style>

<style scoped>
.video-box {
  display: flex;
  /* margin-top: 1rem; */
  background-color: #fafafa;
}
.edu-play-left {
  min-width: 28%;
  max-width: 500px;
  border-right: 1px solid #ebeef5;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* max-height: 50rem;
    overflow: scroll; */
}
.edu-play-right {
  box-sizing: border-box;
  padding: 0 0 10px 0;
  flex: 1;
  padding-bottom: 3rem;
  background-color: #fafafa;
}
::v-deep .el-card__body {
  padding-left: 0;
  padding: 0;
}
.videoContainer {
  border: 1px solid #ebeef5;
  border-radius: 1rem;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
}
.fileContainer {
  /* border: 1px solid #ccc; */
  /* border-radius: 1rem; */
  position: relative;
  /* padding-bottom: 56.25%; */
  padding: 1.5rem;
  overflow: auto;
  min-height: 600px;
  /* background-color: #fafafa; */
}

.videoContainer iframe,
.video-container object,
.video-container embed,
.videoContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
::v-deep .el-collapse-item__wrap {
  background-color: #fafafa;
  background-color: #e3e3e3;
}

::v-deep .el-collapse-item__header {
  height: auto;
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header:hover {
  /* background-color: #fafafa; */
  opacity: 0.8;
}
/* ::v-deep .is-active .el-collapse-item__header {
  background-color: #fafafa;
} */
::v-deep .subject-children .el-collapse-item__header {
  background-color: #fafafa;
}
::v-deep .subject-active .el-collapse-item__header {
  background-color: white;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0;
}

.section-card-list {
  height: 50rem;
  overflow: auto;
}
.section-season {
  position: relative;
  display: flex;
  /* margin-top: 0.5rem; */
  background: white;
  border: 0.5px solid transparent;
  border-radius: 6px;
  font-size: 1rem;
  overflow: visible;
  cursor: pointer;
  margin: 1rem;
}
.season-order {
  width: 2.5rem;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: #9499a0;
  text-align: center;
}
.season-title {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #18191c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 196px; */
}
.season-info {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #61666d;
  word-break: break-all !important;
}
.active {
  cursor: default;
  border: 1px solid var(--themeColor);
  background: rgba(11, 163, 149, 0.05);
}
.section-season:hover {
  border: 1px solid var(--themeColor);
  background: rgba(11, 163, 149, 0.05);
}
.class-title {
  margin-top: 1rem;
}
.block-list-item-info {
  display: flex;
  width: 10rem;
  box-sizing: border-box;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 2rem;
  color: #61666d;
}
.description {
  color: #9499a0;
  white-space: pre-line;
}
.speaker-avatar {
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .video-box {
    display: block;
  }
  .section-card-list {
    min-height: auto;
  }
  .edu-play-right {
    padding-bottom: 0;
  }
  .fileContainer {
    padding: 1rem;
    padding-top: 1.5rem;
    min-height: auto;
  }
}
</style>
